import {
    LOAD_WORKERS_REQUEST,
    LOAD_WORKERS_SUCCESS,
    LOAD_WORKERS_FAILURE,
    ADD_WORKER_INFORMATION_BY_ID
  } from './actionsTypes';

import { getWorkers } from '../../services/workers';
import Worker from '../../models/worker';

export const loadWorkersRequest = () => ({
    type: LOAD_WORKERS_REQUEST,
});


// Action creator for successful load of special days
export const loadWorkersSuccess = (data) => ({
    type: LOAD_WORKERS_SUCCESS,
    payload: data,
  });
  
  // Action creator for failed load of special days
  export const loadWorkersFailure = (error) => ({
    type: LOAD_WORKERS_FAILURE,
    payload: error,
  });

export const addWorkerInformationById = (data) => ({
  type: ADD_WORKER_INFORMATION_BY_ID,
  payload: data
})

export const updateWorkerInformationById = (data) => async(dispatch) => {
  dispatch(addWorkerInformationById(data));
}
// Thunk action to load special days
export const loadWorkers = () => {
  return async (dispatch) => {
    dispatch(loadWorkersRequest());
    try {
      let data = await getWorkers();
      data = data.users;
      const workers = []
      data.map(worker => {
          // const workerModel = new Worker(worker);
          try {
            workers.push(worker);
          } catch (e) {
            console.log(e)
          }
          
      })
      dispatch(loadWorkersSuccess(workers));
    } catch (error) {
      console.log(error)
      dispatch(loadWorkersFailure(error.toString()));
    }
  };
};

export const getWorkerById = ({workers}, workerId) => {
  const worker = workers.data.find(worker => worker.uuid === workerId )
  if(worker) {
    return new Worker(worker)
  } 
  return undefined
}

export const getAllWorkers = ({workers}, active = true) => {
  let workerList = workers.data
  if(active) {
    workerList = workerList.filter(worker => worker.active === active)
  }
  return workerList.map(worker => new Worker(worker))
}