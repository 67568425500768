import { useState, useEffect } from "react";
import React from "react";
import Calendar from "./components/Calendar";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/components/Loading";
import AusenciaForm from "./components/AusenciaForm";
import moment from "moment";
import InfoModal from "../shared/components/InfoModal";
import { exportAusencias, nuevaAusencia } from "../shared/services/ausencias";
import { toast } from "react-toastify";
import Header from "./components/Header";
import PendientesList from "./components/PendientesList";
import Resumen from "./components/Resumen";
import { setErrorMessage } from "../shared/helpers/functionalities";
import ExportModal from "./components/ExportModal";
import { createRegistroHorariosExcel } from "../shared/helpers/registroHorarioExcel";
import { createAusenciasExcel } from "../shared/helpers/ausenciasExcel";
import { getRegistroHorariosUsers } from "../shared/services/registros_horarios";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../models/user';
import { getCompanyById } from '../../../../models/company';
import { loadSpecialDays } from '../../../../redux/special_days/actions';
import { getAllWorkers, loadWorkers } from "../../../../redux/workers/actions";
export default function Inicio() {

    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed)

    const [workerFilter, setWorkerFilter] = useState({company:'', department: '', workerUuid: "-1"});

    const workers = useSelector(state => {
        return getAllWorkers(state)
    });


    const filteredWorkers = workers.filter( worker => {
        let companyFilter = false;
        let departmentFilter = false;
        let workerUuid = false;
        if(workerFilter.company!== '') {
            if(workerFilter.company.id === worker.company.id) {
                companyFilter = true
            }
        } else {
            companyFilter = true
        }
        
        if(workerFilter.department !== '') {
            if(workerFilter.department === worker.department.id) {
                departmentFilter = true
            }
        } else {
            departmentFilter = true
        }

        if(workerFilter.workerUuid !== '-1') {
            if(workerFilter.workerUuid === worker.uuid) {
                workerUuid = true
            }
        } else {
            workerUuid = true
        }
        return companyFilter && departmentFilter &&  workerUuid
    })

    const usersStatus = useSelector(state => {
        return state.users.status
    });


    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });

    const filteredDepartments = useSelector(state => {
        return state.departamentos.filter( dep => {
            if(workerFilter.company !== '') {
                return dep.empresa_id === workerFilter.company.id
            } else {
                return true
            }
            
        })
    });
    
    const companies = useSelector(state => {
        return state.empresas
    });


    const dispatch = useDispatch();

    const [activeDate, setActiveDate] = useState(moment());
    const [openNew, setOpenNew] = useState(false);
    const [openPendientes, setOpenPendientes] = useState(false);
    const [openResumen, setOpenResumen] = useState(false);
    const [openExport, setOpenExport] = useState(false);

    useEffect(() => {
        dispatch(loadSpecialDays())
        const userData = workers.find(candidate => {
            return candidate.uuid === user.id
        })
        if(userData) {
            changeCompany(userData.company);
            changeDepartamento(userData.department.id);
        } 

        // updateNumberOfDays()
        // window.addEventListener("resize", updateNumberOfDays);
        // return () => window.removeEventListener("resize", updateNumberOfDays);
    }, [dispatch]);

    const handleRefresh = () => {
        dispatch(loadWorkers());
    }

    const handleChangeMonth = (month) => { setActiveDate(moment(month)); } 

    const changeDepartamento = (deparmentId) => {
        const filter = {
            ...workerFilter,
            department: deparmentId,
            workerUuid: "-1"
        }
        if(deparmentId === '-1' || deparmentId === -1) {
            filter.department = ''
        }
        setWorkerFilter(filter)
    } 
    
    const handleChangeDepartamento = (e) => {
        const deparmentId = parseInt(e.target.value);
        changeDepartamento(deparmentId)
    }

    const handleChangeUser = (e, user) => {
        let filter = workerFilter;
        if(user === null || user.uuid === "-1") {
            filter = {
                ...workerFilter,
                workerUuid: "-1"
            }
        } else {
            filter = {
                ...workerFilter,
                workerUuid: user.uuid
            }
        }
        setWorkerFilter(filter)
    }


    const changeCompany = (company) => {
        let filter = workerFilter;
        if(company) {
            filter = {
                workerUuid: "-1",
                company: company,
                department: ''
            }
        } else {
            filter = {
                workerUuid: "-1",
                company: '',
                department: ''
            }
        }
        setWorkerFilter(filter)
    }

    const handleChangeCompany = (e) => {
        const company = getCompanyById(parseInt(e.target.value));
        changeCompany(company);
    }

    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);
    const handleOpenPendientes = () => setOpenPendientes(true);
    const handleClosePendientes = () => setOpenPendientes(false);
    const handleOpenResumen = () => setOpenResumen(true);
    const handleCloseResumen = () => setOpenResumen(false);
    const handleOpenExport = () => { setOpenExport(true); }
    const handleCloseExport = () => { setOpenExport(false); }

    const handleNewAusencia = async(ausencia) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await nuevaAusencia(ausencia)
        .catch((error) => {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            setOpenNew(false);
            toast.success('Ausencia creada correctamente!');
            dispatch(loadWorkers());
        }
    }

    const handleExportAusencias = async(data) => {
        
        dispatch({type: 'loading/set', payload: true});
        const response = await exportAusencias(data)
        .catch((error) => {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            const excel = await createAusenciasExcel(
                response.ausencias,
                tipoAusencias
            )

            let fileName = '';
            toast.success('Exportación hecha correctamente!')
          
            const url = window.URL.createObjectURL(new Blob([excel]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';

            if(data.user_id !== -1) {
                const user = workers.find(u => u.id === data.user_id);
                const userName = user.nombre?.replace(' ', '_')?.toUpperCase();
                fileName = 'Ausencias_' + userName + '_' + moment(data.startDate).format('DD-MM-YY') + '_' + moment(data.endDate).format('DD-MM-YY') + '.xlsx';
            } else {
                fileName = 'Ausencias_' + moment(data.startDate).format('DD-MM-YY') + '_' + moment(data.endDate).format('DD-MM-YY') + '.xlsx';
            }
    
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }
    
    const handleExportRegistrosHorarios = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await getRegistroHorariosUsers(data)
        .catch((error) => {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            const excel = await createRegistroHorariosExcel(response.data)

            let fileName = '';
            toast.success('Exportación hecha correctamente!')
            const url = window.URL.createObjectURL(new Blob([excel]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';

            const user = workers.find(u => u.uuid === data.user_id);
            const userName = user ? user.nombre?.replace(' ', '_')?.toUpperCase() : '';
            fileName = 'Registro_Horario_' + userName + '_' + moment(data.startDate).format('DD-MM-YY') + '_' + moment(data.endDate).format('DD-MM-YY') + '.xlsx';
    
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    if(usersStatus.loading === 'pending' || usersStatus.loading === 'idle') {
        return ( <Loading /> );
    }



    if(usersStatus.loading === 'succeed' || usersStatus.loading === 'rejected') {
        return ( 
            <div className="content">
                <Header 
                    user={user}
                    selectedUser={workerFilter.workerUuid}
                    users={filteredWorkers}
                    departamento={workerFilter.department}
                    activeDate={activeDate}
                    onChangeDepartamento={handleChangeDepartamento}
                    onChangeUser={handleChangeUser}
                    onChangeMonth={handleChangeMonth}
                    onOpenNew={handleOpenNew}
                    onOpenPendientes={handleOpenPendientes}
                    onOpenResumen={handleOpenResumen}
                    onOpenExport={handleOpenExport}
                    onChangeCompany={handleChangeCompany}
                    departamentos={filteredDepartments}
                    company={workerFilter.company}
                    companies={companies}
                />

                <Calendar 
                    users={filteredWorkers} 
                    activeDate={activeDate}  
                />

                { (openNew) &&
                    <InfoModal
                        title={"Nueva auséncia"}
                        content={
                            <AusenciaForm
                                onSubmitAusencia={handleNewAusencia}
                            />
                         } 
                        state={openNew}
                        width={'sm'}
                        onClose={handleCloseNew}
                    />
                }

                { (openPendientes) &&
                    <InfoModal
                        title={"Ausencias pendientes"}
                        content={
                            <PendientesList
                                onConfirmAction={handleRefresh}
                            />
                         } 
                        state={openPendientes}
                        width={'md'}
                        onClose={handleClosePendientes}
                    />
                }

                { (openResumen) &&
                    <InfoModal
                        title={"Resumen de ausencias"}
                        content={
                            <Resumen
                                start={moment(activeDate).startOf('month').format('YYYY-MM-DD')}
                                end={moment(activeDate).endOf('month').format('YYYY-MM-DD')}
                            />
                         } 
                        state={openResumen}
                        width={'md'}
                        onClose={handleCloseResumen}
                    />
                }

                { (openExport) &&
                    <InfoModal
                        title={"Exportaciones"}
                        content={
                            <ExportModal 
                                onExportAusencias={handleExportAusencias}
                                onExportRegistroHorarios={handleExportRegistrosHorarios}
                            />
                        } 
                        state={openExport}
                        width={'sm'}
                        onClose={handleCloseExport}
                    />
                }
            </div>
        );
    }
}