// import { applyMiddleware, compose } from "redux";
// import thunk from 'redux-thunk'
import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({reducer: rootReducer, middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false, 
    })});

export default store;