import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import 'moment/locale/es';

export default function Weekdays({
    currDay,
    weekdays,
    breakpoint,
    screenWidth
}) {
    const [days, setDays] = useState([]);
    
    const updateNumberOfDays = () => {
      let currentDays = [];
      let startOfMonth =  moment(currDay).startOf('month');
      //let weekDayStartOfMonth = startOfMonth.getDay();
      let daysInMonth = moment(startOfMonth).daysInMonth();
      
      if (screenWidth < breakpoint) {
        daysInMonth = 7; // Mobile (3 days per row)
      } else {
        daysInMonth = moment(startOfMonth).daysInMonth();
      }


      for (let day = 0; day < daysInMonth; day++) {
        if (day !== 0) {
          startOfMonth = moment(startOfMonth).add(1, 'day')
        }
    
        let calendarDay = {
          currentMonth: (startOfMonth.month === currDay.month),
          date: moment(startOfMonth),
          month: startOfMonth.month(),
          number: startOfMonth.date(),
          selected: (startOfMonth.format('YYYY-MM-DD') === currDay.format('YYYY-MM-DD')),
          year: startOfMonth.year
        }
    
        currentDays.push(calendarDay);
      }

      setDays(currentDays);
    }


    useEffect(() => {
      updateNumberOfDays()
    }, [currDay]);
    
    return (
      <thead>
        <tr>
          {/* white space to avoid the name of the worker */}
          {screenWidth > breakpoint &&
            <th width="8%"></th>
          }
          
          { days.map((day) => {
            return (
              <th key={'date-day-header-' + day.number + '-' + day.month} className="text-center weekday">
                <p>{weekdays[moment(day.date).day()]}</p>
              </th>
            )
          }) }
        </tr>
      </thead>
    )
}