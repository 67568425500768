import Absence from "./absence"
import { getCompanyById } from "./company"

class Worker {
    constructor(apiData) {
        this.uuid = apiData.uuid
        this.dni = apiData.dni
        this.phone = apiData.telefono
        this.startOn = apiData.start_on
        this.endOn = apiData.end_on
        this.name = apiData.nombre
        this.surname = apiData.apellidos
        this.active = apiData.active
        this.diasRestantes = apiData.dias_restantes
        this.diasPendientes = apiData.dias_pendientes
        this.diasTotales = apiData.dias_totales
        this.birthday = apiData.birthday
        this.gender = apiData.genero
        this.company = getCompanyById(apiData.departamento.empresa_id)
        this.department = {
            id: apiData.departamento.id,
            name: apiData.departamento.nombre 
        }
        this.createdAbsences = apiData.createdAbsences.map(absence => new Absence(absence))
        this.email = apiData.email
        this.active = apiData.active
        this.iban = apiData.iban
        this.nacionalidad = apiData.nacionalidad
    }

    getFullName() {
        return `${this.name} ${this.surname}`
    }
    
    toJson() {
        return {
            uuid: this.uuid,
            dni: this.dni,
            telefono: this.phone,
            start_on: this.startOn,
            end_on: this.endOn,
            nombre: this.name,
            apellidos: this.surname,
            active: this.active,
            dias_restantes: this.diasRestantes,
            dias_pendientes: this.diasPendientes,
            dias_totales: this.diasTotales,
            birthday: this.birthday,
            departamento: {
                empresa_id: this.company?.id || null,
                id:this.department.id,
                nombre: this.department.name
            },
            genero: this.gender,
            createdAbsences: this.createdAbsences.map(absence => absence.toJSON()), // Ensure `Absence` class is also serializable
            email: this.email,
            active: this.active,
            iban: this.iban,
            nacionalidad: this.nacionalidad
        };    
    }

    static fromJSON(data) {
        return new Worker(data);
    }
}

export default Worker;