import { toast } from "react-toastify";
import { getUserInfo } from "../views/Main/components/shared/services/users";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";
import { setErrorMessage } from "../views/shared/helpers/functionalities";
import { updateWorkerInformationById } from "./workers/actions";

const asyncUsers = makeAsyncTypes('empleado');

const [setPending, setFullFilled, setError ] = asyncMac(asyncUsers);

export const empleadoFetchingReducer = makeFetchingReducer([
    'empleado/pending', 
    'empleado/fullfilled', 
    'empleado/rejected'
]);

const fullfilledReducer = makeSetReducer(['empleado/fullfilled']);

export const fetchUser = (userId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getUserInfo(userId)
        .catch((error) => {
            toast.error(setErrorMessage(error))
        });
        const data = await response.user;
       
        await dispatch(setFullFilled(data));
        await dispatch(updateWorkerInformationById(data))
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const empleadoReducer = fullfilledReducer;
export const empleadoStatusReducer = empleadoFetchingReducer;