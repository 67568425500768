import { useState } from "react";
import React from "react";
import moment from 'moment';
import CustomDatePicker from "../CustomDatePicker";
import { useSelector } from "react-redux";
import { getCompanyById } from "../../../../../../models/company"
export default function ExportForm({
    dateFilter,
    userFilter,
    isUserNullable,
    tipoAusenciaFilter,
    empresaFilter,
    onSubmitExport
}) {


    const empresas = useSelector(state => {
        return state.empresas
    });

    
    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });
    

    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [selectedUser, setSelectedUser] = useState(-1);
    const [selectedTipoAusencia, setSelectedTipoAusencia] = useState(-1);
    const [selectedEmpresa, setSelectedEmpresa] = useState("");


    const users = useSelector(state => {
        if(selectedEmpresa === "") {
            return state.users.entities
        } else {
            return state.users.entities.filter(user => user.departamento.empresa_id === getCompanyById(selectedEmpresa).id)
        }
        
    });

    const isExportDisabled = () => {
        let disabled = false;

        if(tipoAusenciaFilter)  disabled = (selectedTipoAusencia !== null) ? false : true;
        if(userFilter) disabled = (!isUserNullable && selectedUser !== null) ? false : true;
        if(dateFilter) disabled = (startDateValue !== null && endDateValue !== null) ? false : true ;
        if(empresaFilter) disabled = (selectedEmpresa !== null && selectedEmpresa !== "") ? false : true;

        return disabled;
    }

    const handleChangeUser = (e) => setSelectedUser(e.target.value);
    const handleChangeTipoAusencia = (e) => setSelectedTipoAusencia(parseInt(e.target.value));
    const handleChangeEmpresa = (e) => setSelectedEmpresa(e.target.value === "" ? e.target.value : parseInt(e.target.value));

    const onSubmit = () => {
        let data = {};
        if(dateFilter) {
            data.startDate = startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : null;
            data.endDate = endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null;
        }

        if(userFilter) data.user_id = selectedUser;
        if(tipoAusenciaFilter) data.tipo_ausencia_id = selectedTipoAusencia;
        if(empresaFilter) data.empresa_id = selectedEmpresa;

        onSubmitExport(data);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-column">
                { (dateFilter) &&
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="form-group me-0 me-md-3">
                            <CustomDatePicker 
                                label={"Fecha inicial"}
                                disableFuture={false}
                                minDateValue={null}
                                onChangeDate={setStartDateValue}
                            />
                        </div>
                        
                        <div className="form-group">
                            <CustomDatePicker 
                                label={"Fecha final"}
                                disableFuture={false}
                                minDateValue={startDateValue}
                                onChangeDate={setEndDateValue}
                            />
                        </div>
                    </div>
                }
                {(empresaFilter) &&
                    <div className="form-group">
                        <label>Empresa</label>
                        { (empresas && empresas.length > 0) &&
                            <select 
                                id="empresa_id"
                                defaultValue={""}
                                onChange={handleChangeEmpresa}>
                                <option value={""}>Selecciona una empresa</option>
                                {empresas.map(op => <option key={'empresa-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>
                }

                { (userFilter) &&
                    <div className="form-group">
                        <label>Empleado</label>
                        { (users && users.length > 0) &&
                            <select 
                                id="user_id"
                                defaultValue={-1}
                                onChange={handleChangeUser}>
                                {!isUserNullable ? <option value={-1}>Todos</option> : null}
                                {users.map(op => <option key={op.uuid} value={op.uuid}>{op.nombre} {op.apellidos}</option>)}
                            </select>
                        }
                    </div>
                }

                {(tipoAusenciaFilter) &&
                    <div className="form-group">
                        <label>Tipo de ausencia</label>
                        { (tipoAusencias && tipoAusencias.length > 0) &&
                            <select 
                                id="tipo_ausencia_id"
                                defaultValue={-1}
                                onChange={handleChangeTipoAusencia}>
                                <option value={-1}>Todas</option>
                                {tipoAusencias.map(op => <option key={'tipo-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>
                }

                
            </div>

            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                <button 
                    type="button" 
                    className="btn btn-primary" 
                    disabled={isExportDisabled()}
                    onClick={onSubmit}>Exportar</button>
            </div>
        </div>
    );
}