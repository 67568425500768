class SpecialDayType {
    constructor(id, name, color = "holiday") {
        this.id = id;
        this.name = name;
        this.color = color;
    }
}

const SpecialDayTypes = {
    FESTIVO_LOCAL: new SpecialDayType(1, 'Festivo Local'),
    FESTIVO_NACIONAL: new SpecialDayType(2, 'Festivo Nacional'),
    LABORAL_OBLIGATORIO: new SpecialDayType(3, 'Laboral Obligatorio', 'obligatory-day')
};

// Utility functions
const getSpecialDayTypeById = (id) => Object.values(SpecialDayTypes).find(specialDayType => specialDayType.id === id);
const getSpecialDayTypeByName = (name) => Object.values(SpecialDayTypes).find(specialDayType => specialDayType.name === name);

export {
    SpecialDayType,
    SpecialDayTypes,
    getSpecialDayTypeById,
    getSpecialDayTypeByName
};
    