import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import 'moment/locale/es';
import { CustomTooltip } from "../../../../../../../shared/helpers/functionalities";
import { Badge } from "@mui/material";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import {Companies} from "../../../../../../../../models/company";
import User from "../../../../../../../../models/user";
import {Absences} from "../../../../../../../../models/absences";
import { useKeycloak } from '@react-keycloak/web';

export default function Days({
    currDay,
    user,
    screenWidth,
    breakpoint
}) {
    const fiestasNacionales = useSelector(state => {
      return state.specialDays.data
    });
    const { keycloak } = useKeycloak();
    const currentUser = new User(keycloak.token, keycloak.tokenParsed)

    const [days, setDays] = useState([]);
    const [daysPerRow, setDaysPerRow] = useState(7);

    useEffect(() => {
      const today = moment();
      let currentDays = [];
      let startOfMonth =  moment(currDay).startOf('month');
      let daysInMonth = moment(startOfMonth).daysInMonth();

      for (let day = 0; day < daysInMonth; day++) {
        if (day !== 0) {
          startOfMonth = moment(startOfMonth).add(1, 'day')
        }
    
        let calendarDay = {
          currentMonth: (startOfMonth.month === currDay.month),
          date: moment(startOfMonth),
          month: startOfMonth.month() + 1,
          number: startOfMonth.date(),
          selected: (startOfMonth.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')),
          year: startOfMonth.year()
        }
    
        currentDays.push(calendarDay);
      }

      setDays(currentDays);
      updateDaysPerRow();
    }, [currDay, user]);

  // Function to determine chunk size based on screen width
  const updateDaysPerRow = () => {
    const width = screenWidth;
    if (width < breakpoint) {
      setDaysPerRow(7); // Mobile (3 days per row)
    } else {
      setDaysPerRow(days); // Desktop (7 days per row)
    }
  };


  const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, i) => 
      (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
  };

  const weeks = chunkArray(days, daysPerRow);

  const isHoliday = (date, worker) => {
    return fiestasNacionales.find(d => {
        const curr = d.moment;
        const isHolidayOfACompany = d.isFromACompany();
        // console.log(date, curr)
        if(!d.isInfinite()){
          return curr.isSame(date) && (!isHolidayOfACompany || d.company?.id === worker.company.id);
        } else {
          curr.year(date.year())
          return curr.isSame(date) && (!isHolidayOfACompany || d.company?.id === worker.company.id);
        }
    })
  }

  const isBirthDay = (user, day) => {
    const currentDay = moment(day.date);
    const birthday = user.birthday ? moment(user.birthday) : null;
    if(birthday) {
      return currentDay.month() === birthday.month() && currentDay.date() === birthday.date();
    } else return false;
  }

  const HolidayMark = ({ausencia}) => {
    const addToCalendar = (start, end) => {

      const text = ausencia.absenceType.id === Absences.VACACIONES.id ? "Vacaciones" : ausencia.absenceType.id === Absences.CUMPLEANOS.id ? "Vacaciones por tu Cumpleaños": "Otros";
      const eventTitle = encodeURIComponent(text);
      const eventStart = moment(ausencia.start).format("YYYYMMDD")
      const eventEnd = moment(ausencia.end).add(1,'day').format("YYYYMMDD")
      const eventDetails = encodeURIComponent("");
  
      const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${eventStart}/${eventEnd}&details=${eventDetails}`;
  
      window.open(googleCalendarUrl, "_blank");
    }

    return (
      user.uuid === currentUser.id ? <span 
            onClick={addToCalendar}
            key={'ausencia-symbol-' + ausencia.id }
            className={"circle"} 
            style={{backgroundColor: `${(currentUser.roles.includes('rrhh') || currentUser.roles.includes('admin') || user.uuid === currentUser.id || ausencia.absenceType.id === Absences.TELETRABAJO.id) ? ausencia.absenceType.color : "#000000"}`}}
          ></span> 
          :
          <span 
          key={'ausencia-symbol-' + ausencia.id }
          className={"circle"} 
          style={{backgroundColor: `${(currentUser.roles.includes('rrhh') || currentUser.roles.includes('admin') || user.uuid === currentUser.id || ausencia.absenceType.id === Absences.TELETRABAJO.id) ? ausencia.absenceType.color : "#000000"}`}}
        ></span> )
  
  
  };



  //REFACTOR! 
  const getAbsences = (worker, day) => {
    const currentDay = moment(day.date)
    const ausencias = worker?.createdAbsences?.find(timeOff => {
        const start = moment(timeOff.start);
        const end = moment(timeOff.end);
        if(Companies.RECOMOTOR.name === worker.company.name) {
          if(currentDay.isBetween(start, end, 'days', '[]') && currentDay.day() !== 0 && currentDay.day() !== 6 && !isHoliday(currentDay, worker) && !isBirthDay(currentDay,worker)) {
            return true;
          } else return false;
        } else {
          // ahora conta festaius
          // if(currentDay.isBetween(start, end, 'days', '[]') && !isHoliday(currentDay, user) && !isBirthDay(currentDay,user)) {
            if(currentDay.isBetween(start, end, 'days', '[]')) {
            return true;
          } else return false;
        }
    });
    if(ausencias) {
      return (
        <HolidayMark ausencia={ausencias}/>
         )  
      
    } else return null;
  }
 
  const isWeekend = (day) => {
    return day.date.day() === 0 || day.date.day() === 6
  }

  const getSpecialDayColor = (date, user) => {
    const holiday = fiestasNacionales.find(d => {
      const curr = d.moment;
      const isHolidayOfACompany = d.isFromACompany();
      return curr.isSame(date) && (!isHolidayOfACompany || d.company?.id === user.company.id);
    })
    
    return holiday.type?.color ?? "holiday"
  } 

  const backGroundColor = (day, user) => {
    
    if(day.selected) {
      return "today"
    } else if( isHoliday(day.date, user)) {
      return `current ${getSpecialDayColor(day.date, user)}`
    } else if (isWeekend(day)) {
      return "current weekend"
    } else {
      return "current"
    }
  }

  const weekView = (
    weeks.map((week, weekIndex) => (
    <tr key={"week-" + weekIndex}>
            {week.map((day) => (
              <td 
                key={'user-date-day-' + user.name + user.surname + '-' + day.number + '-' + day.month}
                className={"text-center calendar-day " + backGroundColor(day, user)}
              >
                <div className="d-flex h-100 flex-column align-items-center justify-content-between">
                  <p>{day.number}</p>
                  { (isBirthDay(user, day)) ?
                    <FontAwesomeIcon icon={faCakeCandles} size="1x" className="action" color="#ffd700" />
                    :
                    ''
                  }

                  <div className="w-100 d-flex align-items-center justify-content-center">
                    {getAbsences(user, day)}
                  </div>
                </div>
              </td>
            ))}
            </tr>
  )))

  const listDays = (screenWidth < breakpoint ? 
    weekView
      : days.map((day) => (
        <td 
          key={'user-date-day-' + user.name + user.surname + '-' + day.number + '-' + day.month}
          className={"text-center calendar-day " + backGroundColor(day, user)}
        >
        <div className="d-flex h-100 flex-column align-items-center justify-content-between">
          <p>{day.number}</p>
          { (isBirthDay(user, day)) ?
            <FontAwesomeIcon icon={faCakeCandles} size="1x" className="action" color="#ffd700" />
            :
            ''
          }
          <div className="w-100 d-flex align-items-center justify-content-center">
            {getAbsences(user, day)}
          </div>
        </div>
      </td>
      ))
  ) 


  const view = (screenWidth > breakpoint ? (
    <tr key={'user-' + user.name + user.surname + '-days'}>
      <td>
      
      <CustomTooltip
        title={
            <>
              <div className="w-100">
              
              </div>
            </>
        }>
        <Badge color="primary" badgeContent={(user.diasRestantes - user.diasPendientes)}>
          {user.name + ' ' + user.surname.split(' ')[0]} &nbsp;&nbsp;&nbsp;&nbsp;
        </Badge>
      </CustomTooltip>
    </td>
    { listDays }
    </tr>) 
    : (listDays))



    return (
      <tbody>
        
            {view}
            
      </tbody>
    )
}