import { useEffect } from "react";
import React from "react";
import Weekdays from "./components/Weekdays";
import Days from "./components/Days";
import moment from 'moment';
import 'moment/locale/es';
import './calendar.scss';
import useScreenWidth from "../../../../../../utils/screenWidth";

export default function Calendar({
    users,
    activeDate
}) {

    const breakpoint = 1000;
    const weekdays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Div', 'Sab'];
    const months = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 
                    'Mayo', 'Junio', 'Julio', 'Agosto', 
                    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    useEffect(() => {
        moment.locale('es');
    }, [users, activeDate]);

    const screenWidth = useScreenWidth()

    return (
        <div className="w-100 calendar mt-0 mt-md-3">
            { (users?.length > 0) ?
                <div className="calendar-body">
                    { (activeDate) &&
                        <div className="table-responsive ">

                            {screenWidth > breakpoint ? 
                            (
                                <table className="calendar-table table-primary">
                                    <Weekdays 
                                            currDay={activeDate}  
                                            weekdays={weekdays}
                                            months={months}
                                            screenWidth={screenWidth}
                                            breakpoint={breakpoint}
                                            />
                                    { users.map(user => ( 
                                        <Days 
                                            user={user}
                                            currDay={activeDate}  
                                            screenWidth={screenWidth}
                                            breakpoint={breakpoint}
                                            />
                                    )) }
                                </table>
                            ) 
                            :( 
                                users.map(user => (
                                <div className="container-xl">
                                    
                                <div className="d-flex row text-center"> 
                                    <div className="col-12">
                                        { `${user.name} ${user.surname}`}
                                    </div>
                                    <div className="col-12">
                                    <table className="col-12 calendar-table table-primary">
                                        <Weekdays 
                                            currDay={activeDate}  
                                            weekdays={weekdays}
                                            months={months}
                                            screenWidth={screenWidth}
                                            breakpoint={breakpoint}
                                        />

                                        <Days 
                                            user={user}
                                            currDay={activeDate}  
                                            screenWidth={screenWidth}
                                            breakpoint={breakpoint}
                                            />
                                    </table>
                                    </div>
                                </div>
                                </div>
                                )) 
                            )}
                        </div>
                    }
                </div>
                : <p>No hay usuarios.</p>
            }
        </div>
    );
}