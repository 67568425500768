class Absence {
  constructor(id, name, color) {
    this.id = id;
    this.name = name;
    this.color = color;
  }
}
  
const Absences = {
  VACACIONES: new Absence(1, 'Vacaciones','#ff66f2'),
  AUSENCIA_JUSTIFICADA: new Absence(2, 'Ausencia Justificada','#ffa200'),
  ENFERMEDAD: new Absence(3, 'Enfermedad','#ffea05'),
  TELETRABAJO: new Absence(4, 'Teletrabajo','#00ffcc'),
  MATERNIDAD_PATERNIDAD: new Absence(5, 'Maternidad/Paternidad','#2465ff'),
  OTROS: new Absence(6, 'Otros','#f02d2d'),
  FORMACION: new Absence(7, 'Formación','#a9ff8a'),
  ASUNTOS_PROPIOS: new Absence(8, 'Asuntos propios','#000000'),
  CUMPLEANOS: new Absence(9, 'Cumpleaños','#b31edc'),
};
  
  // Utility functions
const getAbsenceById = (id) => Object.values(Absences).find(absence => absence.id === id);
const getAbsenceByName = (name) => Object.values(Absences).find(absence => absence.name === name);

export {
  Absence,
  Absences,
  getAbsenceById,
  getAbsenceByName,
};
  