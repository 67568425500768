import {
    LOAD_SPECIAL_DAYS_REQUEST,
    LOAD_SPECIAL_DAYS_SUCCESS,
    LOAD_SPECIAL_DAYS_FAILURE,
  }  from './actionsTypes'; 

import { getSpecialDays } from '../../services/special_days';
import SpecialDay from '../../models/specialDay';

export const loadSpecialDaysRequest = () => ({
    type: LOAD_SPECIAL_DAYS_REQUEST,
});


// Action creator for successful load of special days
export const loadSpecialDaysSuccess = (data) => ({
    type: LOAD_SPECIAL_DAYS_SUCCESS,
    payload: data,
  });
  
  // Action creator for failed load of special days
  export const loadSpecialDaysFailure = (error) => ({
    type: LOAD_SPECIAL_DAYS_FAILURE,
    payload: error,
  });
  
  // Thunk action to load special days
  export const loadSpecialDays = () => {
    return async (dispatch) => {
      dispatch(loadSpecialDaysRequest());
      try {
        let data = await getSpecialDays();
        data = data.specialDays;
        const specialDays = []
        data.forEach(specialDay => {
            const objectSD = new SpecialDay(specialDay);
            specialDays.push(objectSD);
        })
        dispatch(loadSpecialDaysSuccess(specialDays));
      } catch (error) {
        console.log(error)
        dispatch(loadSpecialDaysFailure(error.toString()));
      }
    };
  };