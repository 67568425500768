import { useState, useEffect, useCallback } from 'react';

const useScreenWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);    
    const handleResize = useCallback(() => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => setWidth(window.innerWidth), 100);
        };
    }, []);   
    
    useEffect(() => {
        const debouncedResize = handleResize();
        window.addEventListener('resize', debouncedResize);
        return () => {
            window.removeEventListener('resize', debouncedResize);
        };
    }, []);    
    
    return width;
};

export default useScreenWidth;