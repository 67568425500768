import { useEffect } from "react";
import React from "react";
import Admin from "./Admin";
import Empleado from "./Empleado";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../models/user';


export default function Documentos() {
    // const user = JSON.parse(localStorage.getItem('user'));

    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed)

    useEffect(() => { 
    }, []);

    return (
        <div className="content">
            { (user.roles.includes('rrhh') || user.roles.includes('admin')) ?
                <Admin />
                :
                <Empleado />
            }
        </div>
    );
}