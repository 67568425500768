import { getAbsenceById } from "./absences"
import moment from "moment";

class Absence {
    constructor(apiData) {
        this.id = apiData.id
        this.createdAt = moment(apiData.fecha)
        this.start = moment(apiData.fecha_inicio)
        this.end = moment(apiData.fecha_fin)
        this.totalDays = apiData.total_dias
        this.approved = apiData.approved ?? null
        this.createdBy = {
            id: apiData.created_by,
            name: apiData.createdBy?.nombre,
            surname: apiData.createdBy?.apellidos
        }
        this.absenceType = getAbsenceById(apiData.tipo_ausencia_id)
    }


    toJSON() {
        const jsonData = {
            id: this.id,
            fecha: this.createdAt.toISOString(), // Convert Moment.js to string
            fecha_inicio: this.start.toISOString(), // Convert Moment.js to string
            fecha_fin: this.end.toISOString(), // Convert Moment.js to string
            total_dias: this.totalDays,
            approved: this.approved ?? null,
            created_by: this.createdBy.id,
            createdBy: {
                nombre: this.createdBy.name,
                apellidos: this.createdBy.surname
            },
            tipo_ausencia_id: this.absenceType?.id || null // Store only the ID for serialization
        };
        return jsonData;
    }

    static fromJSON(data) {
        const absence = new Absence(data)
        return absence;
    }
}

export default Absence;