import {getSpecialDayTypeById} from "./specialDayType"
import {getCompanyById} from "./company"
import moment from 'moment';

class SpecialDay {
    constructor(apiSpecialDay) {
        this.id = apiSpecialDay.id
        this.description = apiSpecialDay.description
        this.type = getSpecialDayTypeById(apiSpecialDay.type)
        this.day = apiSpecialDay.day
        this.month= apiSpecialDay.month
        this.year = apiSpecialDay.year ?? new Date().getFullYear()
        this.infinite = apiSpecialDay.year ? false : true;
        this.company = getCompanyById(apiSpecialDay.company)
        this.moment = moment(this.year+ '-' + (this.month < 10 ? ('0' + this.month) : this.month) + '-' + (this.day < 10 ? ('0' + this.day) : this.day))
    }

    getDate(){
        return `${this.day}-${this.month}${this.year ? `-${this.year}` : '' }`;
    }

    isInfinite() {
        return this.infinite
    }

    isFromACompany() {
        return this.company !== undefined && this.company !== null;
    }

    affectsWorker(worker) {
        const workerCompany = getCompanyById(worker.departamento.empresa_id);
        const companyHoliday = this.isFromACompany();
        if(companyHoliday) {
            return this.company.id === workerCompany.id;
        } 
        return true
    }
    
}

export default SpecialDay;