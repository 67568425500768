import {
    LOAD_WORKERS_REQUEST,
    LOAD_WORKERS_SUCCESS,
    LOAD_WORKERS_FAILURE,
    ADD_WORKER_INFORMATION_BY_ID
  } from './actionsTypes';
  
const initialState = {
    loading: false,
    data: [],
    error: null
}


const workersReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOAD_WORKERS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case LOAD_WORKERS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case LOAD_WORKERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case ADD_WORKER_INFORMATION_BY_ID:
        const worker = state.data.find(worker => worker.uuid === action.payload.uuid)
        if(!worker) {
          state.data.push(action.payload)
        } else {
          Object.assign(worker, action.payload)
        }
        return {
          ...state,
          loading: false,
          error: action.payload,
        };        
      default:
        return state;
    }
  };
  
  export default workersReducer;