import {
    LOAD_NATIONALITIES_REQUEST,
    LOAD_NATIONALITIES_SUCCESS,
    LOAD_NATIONALITIES_FAILURE,
  } from './actionsTypes';
  
const initialState = {
    loading: false,
    data: [],
    error: null
}


const nationalitiesReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOAD_NATIONALITIES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case LOAD_NATIONALITIES_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case LOAD_NATIONALITIES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default nationalitiesReducer;