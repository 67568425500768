import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFileExcel, faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField } from "@mui/material";
import DateSelectorSimple from "../../../shared/components/DateSelectorSimple";
import { getCompanyById } from "../../../../../../models/company";
import useScreenWidth from "../../../../../../utils/screenWidth";


export default function Header({
    user,
    users,
    departamento,
    departamentos,
    company,
    companies,
    activeDate,
    onChangeDepartamento,
    onChangeUser,
    onChangeMonth,
    onChangeCompany,
    onOpenNew,
    onOpenPendientes,
    onOpenResumen,
    onOpenExport,
    selectedUser
}) {
    let list = [{uuid: "-1", name: 'Todos', surname: ''}]
    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });

    const workerList = () => {
        list = [{uuid: "-1", name: 'Todos', surname: ''}, ...users]
        return list
    }

    const loadingWorkers = useSelector(state => {
        return state.workers.loading
    })

    const screenWidth = useScreenWidth()
    const breakpoint = 1000

    return (
        <div className="">
            <div className="d-flex flex-column">
                <div className="w-100 d-flex flex-wrap flex-lg-nowrap">
                    <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-start gap-4">
                        { (user.roles.includes('admin') || user.roles.includes('responsable') || user.roles.includes('rrhh')) &&
                            <div className="flex-grow-1 flex-md-grow-0">
                                <label>Empresa:</label>
                                <select 
                                    id="company_id"
                                    name="company_id"
                                    defaultValue={company}
                                    onChange={onChangeCompany}>
                                    <option value={-1}>Todos</option>
                                    {companies?.map(company => {
                                        return <option key={company.id} value={company.id}>{company.nombre}</option>
                                        })}
                                </select>
                                </div>
                        }    
                    
                        <div className="flex-grow-1 flex-md-grow-0">
                            <label>Departamento:</label>
                            <select 
                                id="departamento_id"
                                name="departamento_id"
                                // defaultValue={departamento}
                                onChange={onChangeDepartamento}>
                                <option value={-1}>Todos</option>
                                {departamentos?.filter(dep => {
                                    const companyDepartment = getCompanyById(dep.empresa_id);
                                    return ((user.company === companyDepartment.name || user.roles.includes('admin') || user.roles.includes('rrhh')) && dep.id !== 9  )
                                })
                                .map(dep =>  <option selected={dep.id === departamento} key={'departamentos-' + dep.id} value={dep.id}>{dep.nombre}</option> )}
                            </select>
                        </div>
                        { !loadingWorkers &&        
                        <div className="flex-grow-1 flex-md-grow-0">
                            <label>Empleado:</label>
                            <Autocomplete
                                disablePortal
                                id="owner_id"
                                options={workerList()}
                                // defaultValue={selectedUser}
                                getOptionLabel={(option) => `${option['name']} ${option['surname'] ? option['surname'] : ''}`}
                                onChange={onChangeUser}
                                isOptionEqualToValue={(option, value) => {
                                    return option.uuid === value?.uuid
                                }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                    />
                                }
                            />
                        </div>
                        }
                    </div>

                    <div className={`w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-end mt-4 mt-lg-0 gap-4`}>
                        { (user.roles.includes('admin') || user.roles.includes('responsable') || user.roles.includes('rrhh')) &&
                            <>
                                <button className="btn btn-add flex-grow-1 flex-lg-grow-0" onClick={() => onOpenExport()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" />
                                    </span>
                                    Exportar
                                </button>

                                <button className="btn btn-add flex-grow-1 flex-lg-grow-0" onClick={() => onOpenResumen()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faList} size="1x" className="action" color="#215732" />
                                    </span>
                                    Resumen
                                </button>
                            </>
                        }
                        {/* { (!user.roles.includes('admin') && !user.roles.includes('responsable') && !user.roles.includes('rrhh')) &&
                            <>
                                <button className="btn btn-add me-3" onClick={() => onOpenUploadDocument()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faUpload} size="1x" className="action" color="#215732" />
                                    </span>
                                    Subir Documento
                                </button>
                            </>
                        } */}

                        { (user.roles.includes('admin') || user.roles.includes('rrhh')) &&
                            <>
                                <button className="btn btn-add flex-grow-1 flex-lg-grow-0" onClick={() => onOpenPendientes()}>
                                    <span className="add">
                                        <FontAwesomeIcon icon={faClock} size="1x" className="action" color="#215732" />
                                    </span>
                                    Pendientes
                                </button>
                            </>
                        }
                        
                        <button className={`btn btn-add flex-grow-1 flex-lg-grow-0`} onClick={() => onOpenNew()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                            </span>
                            Nueva ausencia
                        </button>
                    </div>
                </div>
               

                <div className="w-100 d-flex justify-content-center align-items-center text-center my-4">
                    <div className="col-12 col-md-6">
                        <DateSelectorSimple
                            currDay={activeDate}  
                            onSelectedDate={onChangeMonth}
                        />
                    </div>
                </div>
            </div>
            
            { screenWidth > breakpoint &&
                <div className="d-flex   justify-content-between">
                    { tipoAusencias?.map((tipo, i) => {
                            return (
                                <div key={'legend-' + tipo.id} className={"d-flex align-items-center "}>
                                <span className="circle me-1" style={{backgroundColor: tipo.color}}></span>
                                <small>{tipo.nombre}</small>
                                </div>
                            )
                    }) }

                    <div className={"d-flex align-items-center"}>
                        <span className="circle me-1" style={{backgroundColor: '#a8f8c1'}}></span>
                        <small>Fiesta Nacional</small>
                    </div>
                    

                    <div className={"d-flex align-items-center"}>
                        <span className="circle me-1" style={{backgroundColor: '#6a8605'}}></span>
                        <small>Dia bloqueado</small>
                    </div>
                </div>
            }
            
        </div>
    );
}