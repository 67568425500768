import ExcelJS from 'exceljs';
import { Absences, getAbsenceById } from '../../../../../models/absences';

export const createRegistroHorariosExcel = async(
    data
) => {
      
    const workbook = new ExcelJS.Workbook();

        data.forEach(user => {
            try {
                const sheet = workbook.addWorksheet(`${user.name} ${user.surname}`);
                sheet.columns = [
                    { header: 'Empleado', key: 'employee', width: 45, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Dia', key: 'date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Tipo', key: 'date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Hora de entrada', key: 'morning', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Hora de salida', key: 'afternoon', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas ordinarias', key: 'ordinary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas complementarias', key: 'complementary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas Justificadas', key: 'justified_hours', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}}
                ];
                user.dates.forEach(date => {
                    const row = [
                        `${user.name} ${user.surname}`,
                        date.day,
                        date.type,
                        date.schedule.morning,
                        date.schedule.afternoon,
                        date.worked_hours,
                        date.complementary_hours
                    ]
                    if(date.type == Absences.AUSENCIA_JUSTIFICADA.name) {
                        const justifiedHours = date.justified_hours ?? 8
                        row.push(justifiedHours)
                    }
                    sheet.addRow(row)
                })

            } catch (error) {
                console.log(error)
            }
        })
    return await workbook.xlsx.writeBuffer();
}