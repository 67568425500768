import React from 'react';
import { useState, useEffect } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function DateSelector({
    currDay,
    onSelectedDate
}) {
    const [initialDateInterval, setInitial] = useState(moment(currDay).startOf('month').format('YYYY-MM-DD'));
    const [finalDateInterval, setFinal] = useState(moment(currDay).endOf('month').format('YYYY-MM-DD'));
    let counterPrev = 0;
    let counterNext = 0;

    useEffect(() => {}, []);

    const periodSelection = (period) => {
        if(period === 'prev') {                         // previous date
            if (counterPrev > 0) counterPrev = 0;
            counterPrev++;
        
            prevDate();
        } else {                                        // next date
            if (counterNext > 0) counterNext = 0;
            counterNext++;

            nextDate();
        }
    }

    const nextDate = () => {
        let start;
        let end;

        start = moment(initialDateInterval).add(counterNext, 'month').startOf('month').format('YYYY-MM-DD')
        end = moment(initialDateInterval).add(counterNext, 'month').endOf('month').format('YYYY-MM-DD');

        setInitial(start);
        setFinal(end);
        onSelectedDate(moment(initialDateInterval).add(counterNext, 'month').startOf('month'));
    }

    const prevDate = () => {
        let start;
        let end;
        start = moment(initialDateInterval).subtract(counterPrev, 'month').startOf('month').format('YYYY-MM-DD');
        end = moment(initialDateInterval).subtract(counterPrev, 'month').endOf('month').format('YYYY-MM-DD');

        setInitial(start);
        setFinal(end);
        onSelectedDate(moment(initialDateInterval).subtract(counterPrev, 'month').startOf('month'));
    }

    return (
        <div className="align-items-center date-selector row ">
            <div className="col-4">
                <button 
                    type="button" 
                    className="btn btn-arrow"
                    onClick={() => periodSelection('prev')}>
                        <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                </button>
            </div>

            <div  className="col-4">
                <p className="mb-0 text-capitalize fw-bold">{moment(initialDateInterval).format("MMMM YYYY")}</p>
            </div>

            <div  className="col-4">
                <button type="button"
                    className="btn btn-arrow"
                    onClick={() => periodSelection('next')}>
                        <FontAwesomeIcon icon={faChevronRight} size="2x" />
                </button>
            </div>   
        </div>
    )
}
